const timeline =() => {
    return(
        <>
            <div class="timeline">
                <div class="timeline-item">
                <div class="timeline-content">
                    <h2>Education</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                </div>

                <div class="timeline-item">
                <div class="timeline-content">
                    <h2>Work Experience</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                </div>
            </div>

        </>
    )
}

export default timeline;